<template>
    <div class="seleTest xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>健康自测</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>健康自测</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content">
                <div class="top-div flex-start">
                    <div class="left-img"></div>
                    <div class="top-title">健康自测题库</div>
                    <div class="right-btn1" @click="selfTest()">
                        <span>自测记录</span>
                    </div>
                </div>
                <div class="center-div  flex-start flex-wrap">
                    <div class="item-div flex-space-between-columns" v-for="(item, index) in dataList" :key="index">
                        <div class="item-top">
                            <div class="title1 oneLines">{{ item.Questionnaires.name }}
                            </div>
                            <div class="title2"><span>最近一次结果：</span><span @click="toResult(item)">{{
                                item.UserResult || "暂无" }}</span>
                            </div>
                        </div>
                        <div class="bottom-top flex-space-between">
                            <div class="left-desc">
                                <p>最近一次时间</p>
                                <p v-if="item.LastTest.time">{{ item.LastTest.time | timefilters }}</p>
                                <p v-else>暂无</p>
                            </div>
                            <div class="right-btn2" @click="startTest(item.Questionnaires)">
                                <span>开始测试</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
// import rtBar from "../../components/right";
import { Test } from '../../components/company/test';
export default {
    name: "selfTest",
    components: {
        hdCom,
        ftCom,
        // rtBar
    },
    data() {
        var test = new Test(this.TokenClient, this.Services.Statics)
        return {
            testDomain: test,
            userInfo: this.$store.state.localData.userInfo,
            dataList: [{
                Questionnaires: {
                    name: ''
                },
                LastTest: {
                    time: ""
                }
            }],
        }
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.getQuestionnaire()
    },
    methods: {
        selfTest() { 
            this.$router.push('/personal/SelfTest')
        },
        toResult(item) {
            console.log(item);
            const href = this.$router.resolve({
                path: '/company/testResult',
                query: {
                    resId: item.LastTest.questionnaireDetermines[0].userQuestionnaireId,
                    answerUserId: item.LastTest.id,
                },
            })
            window.open(href.href, '_blank')
        },
        startTest(Questionnaires) {
            this.$router.push({
                path: "/company/startTest",
                query: {
                    qId: Questionnaires.id,
                    titleName: Questionnaires.name
                },
            });
        },
        getQuestionnaire() {
            this.testDomain.Questionnaire(
                (res) => {
                    this.dataList = []
                    res.data.forEach((item, index) => {
                        var list = res.data
                        var quesId = item.id
                        var array = list.filter(x => x.id == quesId)
                        var resMap = {
                            Questionnaires: array[0],
                            LastTest: {
                                Id: 0,
                                customerName: "",
                                questionnaireDetermines: [],
                            },
                            UserResult: '',
                        }
                        this.testDomain.UserResultAll(this.userInfo.id, quesId,
                            (data) => {
                                var str = false
                                resMap.LastTest = data.data[0]
                                if (resMap.LastTest.questionnaire.name == '中医体质辨识') {
                                    resMap.LastTest.questionnaireDetermines.forEach(function (itemInfo, index) {
                                        if (itemInfo.name == "平和质") {
                                            str = true
                                        }
                                    });
                                    resMap.UserResult = str ? "平和质" : '偏颇体质';
                                } else {
                                    resMap.UserResult = resMap.LastTest.questionnaireDetermines[0].name
                                }
                            },
                            (error) => {
                                console.log("请求失败!请刷新重试:", error);
                            });
                        this.dataList.push(resMap)
                    })

                },
                (error) => {
                    console.log("请求失败!请刷新重试:", error);
                }
            );
        },
    }
}
</script>

<style scoped>
.banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner.png")center no-repeat;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 18px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.content-wrap {
    background: #F5F6F6;
}

.content {
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    padding: 50px 0;
}

.top-div {
    margin-bottom: 30px;
}

.top-div .left-img {
    width: 24px;
    height: 24px;
    border: 5px solid #EBEEFD;
    background: linear-gradient(90deg, #4DB992, #81DFA7);
    box-shadow: 0px 3px 6px 0px rgba(18, 184, 149, 0.3);
    border-radius: 50%;
    margin-right: 10px;
}

.top-div .top-title {
    width: auto;
    height: 29px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
}

.center-div .item-div {
    width: 28.5%;
    height: 160px;
    margin-right: 2%;
    margin-bottom: 20px;
    background: linear-gradient(-55deg, #EAF9F6, #FFFFFF);
    border: 1px solid #DDEFED;
    box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.3);
    border-radius: 16px;
    cursor: pointer;
    align-items: flex-start !important;
    padding: 20px 20px;
}

.center-div .item-div:nth-child(3n) {
    margin-right: 0;
}

.center-div .item-div .title1 {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
}

.center-div .item-div .title2 {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.center-div .item-div .left-desc {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
}

.center-div .item-div .title2 span:last-child {
    color: #009583;
}

.center-div .item-div .bottom-top {
    width: 100%;
}
.right-btn1 {
    width: 110px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(180deg, #03CDB4, #00A28E);
    box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.15);
    border-radius: 18px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
}
.right-btn2 {
    width: 110px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(90deg, #03CDB4, #00A28E);
    box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.15);
    border-radius: 18px;
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.right-btn2 {
    width: 110px;
    height: 36px;
    line-height: 36px;
    background: linear-gradient(90deg, #03CDB4, #00A28E);
    box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.15);
    border-radius: 18px;
    text-align: center;
    color: #fff;
    font-size: 16px;
}
</style>